import { OKTA_API_URL } from '../config'; // Importing the API URL from a separate configuration file

const fetchGroups = async countryCode => {
    const queryParams = `/api/v1/groups?search=profile.rbGroupType%20eq%20"organization"%20and%20profile.countryCode%20eq%20"${countryCode}"%20and%20profile.rbGroupStatus%20eq%20"ACTIVE"`;

    try {
        const response = await fetch(`${OKTA_API_URL}&path=${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        let data = await response.json();

        // Sorts the result alphabetically according to the name of the groups
        data.sort((a, b) => a.profile.name.localeCompare(b.profile.name));

        return data; // Returns the sorted data
    } catch (error) {
        console.error('Failed to fetch groups:', error);
        throw error;
    }
};

export default fetchGroups;
