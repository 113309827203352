import { OKTA_WORKFLOW_REQUEST_URL } from '../config'; // Importing the API URL from a separate configuration file

const createRequest = async (oktaID, groupID) => {
    const url = OKTA_WORKFLOW_REQUEST_URL;
    const body = { oktaID, groupID };

    // Check that oktaID or groupID is empty or null.
    if (!oktaID || !groupID) {
        console.error('Error invoking workflow: Object not complete');
        throw {
            status: 500,
            message: 'Object not complete. Both oktaID and groupID are required.',
            errorCode: 'InvalidInput',
        };
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        });

        // Successful return of the JSON response and the status if the response is between 200 and 300
        if (response.status >= 200 && response.status < 300) {
            return {
                status: response.status,
                data: await response.json(),
            };
        }

        if (!response.ok) {
            // Throws a customised error that includes the status code
            throw {
                status: response.status, // Retain the status code
                message: `HTTP error! status: ${response.status}`,
                isAxiosError: true,
            };
        }

        return await response.json();
    } catch (error) {
        console.error('Error invoking workflow:', error);
        if (!error.isAxiosError) {
            // Handle non-Axios errors that might not have a status field
            error.status = 500;
        }
        throw error; // Passing on the adjusted error
    }
};

export default createRequest;
