import { OKTA_API_URL } from '../config'; // Importing the API URL from a separate configuration file
/**
 * Creates a new user in Okta with the given details.
 * @param {Object} userDetails - Details of the user to be created.
 */

const createUser = async userDetails => {
    const { firstName, lastName, email } = userDetails;
    const url = `${OKTA_API_URL}&path=/api/v1/users?activate=false`; // URL for the Okta API.

    const headers = {
        'Content-Type': 'application/json',
        'Accept-Language': 'en-US', // Ensures that the answer is in English
    };
    const body = {
        profile: {
            firstName,
            lastName,
            email,
            login: email,
            selfRegister: true,
            regComplete: false,
        },
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        });

        const data = await response.json(); // Always convert the response to JSON, regardless of success or error

        if (!response.ok) {
            // Determine the message based on the presence and content of errorSummary
            let message = 'An error has occurred. The user could not be created. Please try again.';
            if (data.errorCauses && data.errorCauses.length > 0 && data.errorCauses[0].errorSummary) {
                message = data.errorCauses[0].errorSummary;
            } else if (data.errorSummary) {
                message = data.errorSummary;
            }

            return {
                responseNumber: response.status, // Use the actual status code of the response
                success: false,
                errorCode: data.errorCode,
                message: message, // Use the specific message
            };
        }

        // In case of success
        return {
            responseNumber: response.status,
            success: true,
            data: data, // The successful response from Okta
        };
    } catch (error) {
        // This block catches network errors and other unexpected errors that could occur when calling the fetch function
        console.error('Error creating user:', error);
        // As this is a network error, we do not have a status code or error code from Okta
        return {
            responseNumber: 0, // No status code available
            success: false,
            errorCode: 'NetworkError',
            message: 'An unexpected network error occurred.',
        };
    }
};
export default createUser;
