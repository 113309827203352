// Funktion, um den Standort des Benutzers mithilfe von Azure Maps zu ermitteln, über eine Azure Function
import { AZURE_MAPS_IP_LOCALISATION } from '../config'; // Importing the API URL from a separate configuration file

const readUserLocation = async () => {
    try {
        // URL der Azure Function, die die Geolocation basierend auf der IP-Adresse abruft
        const functionUrl = AZURE_MAPS_IP_LOCALISATION; // Die URL deiner Azure Function hier einsetzen

        // Anfrage an die Azure Function

        const response = await fetch(functionUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            const data = await response.json();


            if (data.countryRegion.isoCode === 'US') {
                return data
            } else {
                return null;
            }

        } else {
            console.error('Fehler beim Abrufen des Standorts von der Azure Function:', response.status, response.statusText);
            return null;
        }
    } catch (error) {
        console.error('Fehler bei der Standortabfrage von der Azure Function:', error);
        return null;
    }
};

export default readUserLocation;