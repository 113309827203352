import countries from '../helper/country.json'; // Import the JSON data

const fetchIsoCodes = async () => {
    try {
        // No network request is necessary as the data is available locally

        // Process the data and sort it alphabetically by country name
        const isoOptions = countries
            .map(country => ({
                value: country.cca2, // Use the CCA2 code as the value
                label: `${country.cca2} - ${country.name.common}`, // Combine CCA2 and the general name for the label
            }))
            .sort((a, b) => a.label.localeCompare(b.label)); // Alphabetical sorting

        // Return of the processed and sorted ISO options
        return isoOptions;
    } catch (error) {
        console.error('Failed to load ISO codes:', error);
        throw error;
    }
};

export default fetchIsoCodes;
