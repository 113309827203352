import React, { useState, useEffect } from 'react';
import './App.css';
import { loadBackground } from './utils/styleutils';
import RegistrationForm from './components/RegistrationForm';
import RegistrationSuccess from './components/RegistrationSuccess';
import RegistrationFalse from './components/RegistrationFalse';
import MaintenancePage from './components/MaintenancePage';

function App() {
    const [registrationStatus, setRegistrationStatus] = useState({
        isRegistered: false,
        isSuccess: false,
    });

    const isMaintenance = process.env.REACT_APP_MAINTENANCE === 'true';

    const handleRegistrationSuccess = isSuccess => {
        setRegistrationStatus({
            isRegistered: true,
            isSuccess: isSuccess,
        });
    };

    if (isMaintenance) {
        return (
            <div className="App">
                <MaintenancePage />
            </div>
        );
    }

    return (
        <div className="App">
            {!registrationStatus.isRegistered ? (
                <RegistrationForm onRegistrationSuccess={handleRegistrationSuccess} />
            ) : registrationStatus.isSuccess ? (
                <RegistrationSuccess />
            ) : (
                <RegistrationFalse />
            )}
        </div>
    );
}

export default App;
