import React from 'react';
import logoImage from '../utils/images/logo.png'; // Import the logo image from the local folder
import './Logo.css';

const Logo = ({ src }) => {
    return (
        <div className="logo-container">
            {/* Loading from the internet was replaced with an image in the project folder */}
            {/* <img src="https://cdn.api.redbull.com/images/partner_id/redbull-partnerid-logo-color-centered.png" alt="Logo" /> */}
            <img src={logoImage} alt="Logo" /> {/* Use the imported path as the src attribute */}
        </div>
    );
};

export default Logo;
