// Improved e-mail validation function
export const validateEmail = email => {
    // Bestehende E-Mail-Regex zur Strukturüberprüfung
    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    if (!emailRegex.test(email)) {
        return false;
    }

    // Konvertiere die E-Mail-Adresse in Kleinbuchstaben für eine case-insensitive Prüfung
    const emailLower = email.toLowerCase();

    // Teile die E-Mail-Adresse in lokalen Teil und Domain-Teil
    const [localPart, domainPart] = emailLower.split('@');

    // Überprüfe, ob 'rbcd' im Domain-Teil enthalten ist
    if (domainPart.includes('rbcd')) {
        return false;
    }

    // Regex-Muster für ungültige "redbull"-Variationen
    const invalidPattern = /red[\W_]*bull/i;

    // Überprüfe, ob die E-Mail-Adresse das ungültige Muster enthält
    if (invalidPattern.test(emailLower)) {
        return false;
    }

    // E-Mail ist gültig
    return true;
};
