import React from 'react';
import './RegistrationSuccess.css';
import Logo from '../utils/Logo';
import { OKTA_SUCCESS_URL } from '../config'; // Importing the API URL from a separate configuration file

const RegistrationSuccess = () => {
    // Function for changing the page to a link
    const handleCompleteRegistration = () => {
        window.location.href = OKTA_SUCCESS_URL;
    };

    return (
        <div className="registration-success-container">
            <div className="registration-success">
                <div className="logo-placeholder">
                    <Logo />
                </div>
                <p className="success-message">
                    <strong className="bold-text">Almost There!</strong>
                    <br />
                    To finalize your account setup, an administrator from your organization needs to verify your identity. Don't
                    worry, you will receive further instructions via email soon.
                </p>
                <button type="submit" className="button" onClick={handleCompleteRegistration}>
                    Back to Sign In
                </button>
            </div>
        </div>
    );
};

export default RegistrationSuccess;
